<template>
  <div id="g-layout">
    <the-header></the-header>
    <nuxt />
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from '@/components/shared/headers/SimpleHeader.vue'
import TheFooter from '@/components/shared/footers/SimpleFooter.vue'
export default {
  name: 'SimpleLayout',
  components: {
    TheHeader,
    TheFooter
  }
}
</script>
