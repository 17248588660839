export const state = () => ({
  projectId: 'e1ba78d4-146b-4ea6-9a0c-fc4ef14c4ffd',
  mapBbox: {
    minX: 9.878769,
    minY: 9.960437,
    maxX: 57.016650,
    maxY: 57.038535
  },
  manualSensors: null,
  inputTypeList: null,
  selectedLanguage: 'da',
  resetZoomButtonContent: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><title>Nulstil zoom niveau</title><path d="M10.16,13.56l3.39-3.25a.76.76,0,0,1,1,0L18,13.59a.75.75,0,0,1,.18.83.77.77,0,0,1-.63.48v2.82a.76.76,0,0,1-.76.77H11.34a.76.76,0,0,1-.76-.77V14.9a.77.77,0,0,1-.69-.76A.78.78,0,0,1,10.16,13.56ZM8.28,8.67a8.08,8.08,0,0,1,12.56,10l4.93,4.77a.77.77,0,0,1,0,1.08l-1,1a.72.72,0,0,1-.53.23h0a.75.75,0,0,1-.53-.22l-4.91-4.76a8,8,0,0,1-4.77,1.56h0A8.07,8.07,0,0,1,8.28,8.67ZM9.51,19a6.56,6.56,0,1,0-.14-9.27A6.53,6.53,0,0,0,9.51,19Z" fill="#fff"/></svg>'
})

export const getters = {
  getProjectId: (state) => {
    return state.projectId
  },
  getMapBbox: (state) => {
    return state.mapBbox
  },
  getManualSensors: (state) => {
    return state.manualSensors
  },
  getInputTypeList: (state) => {
    return state.inputTypeList
  },
  getSelectedLanguage: (state) => {
    return state.selectedLanguage
  },
  getResetZoomButtonContent: (state) => {
    return state.resetZoomButtonContent
  }
}

export const mutations = {
  setManualSensors: (state, payload) => {
    state.manualSensors = payload
  },
  setInputTypeList: (state, payload) => {
    state.inputTypeList = payload
  }
}

export const actions = {
  setManualSensors: ({ commit }, data) => {
    commit('setManualSensors', data)
  },
  setInputTypeList: ({ commit }, data) => {
    commit('setInputTypeList', data)
  }
}
