import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0da84390 = () => interopDefault(import('..\\pages\\about.vue' /* webpackChunkName: "pages_about" */))
const _00708af6 = () => interopDefault(import('..\\pages\\images.vue' /* webpackChunkName: "pages_images" */))
const _e3d8f500 = () => interopDefault(import('..\\pages\\informationsmøde.vue' /* webpackChunkName: "pages_informationsmøde" */))
const _248af6a0 = () => interopDefault(import('..\\pages\\news.vue' /* webpackChunkName: "pages_news" */))
const _0c679874 = () => interopDefault(import('..\\pages\\overview.vue' /* webpackChunkName: "pages_overview" */))
const _58aba99e = () => interopDefault(import('..\\pages\\reporting\\index.vue' /* webpackChunkName: "pages_reporting_index" */))
const _038ae949 = () => interopDefault(import('..\\pages\\supported_browsers.vue' /* webpackChunkName: "pages_supported_browsers" */))
const _a759049a = () => interopDefault(import('..\\pages\\reporting\\_sensorId.vue' /* webpackChunkName: "pages_reporting__sensorId" */))
const _2d601e55 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0da84390,
    name: "about"
  }, {
    path: "/images",
    component: _00708af6,
    name: "images"
  }, {
    path: "/informationsmøde",
    component: _e3d8f500,
    name: "informationsmøde"
  }, {
    path: "/news",
    component: _248af6a0,
    name: "news"
  }, {
    path: "/overview",
    component: _0c679874,
    name: "overview"
  }, {
    path: "/reporting",
    component: _58aba99e,
    name: "reporting"
  }, {
    path: "/supported_browsers",
    component: _038ae949,
    name: "supported_browsers"
  }, {
    path: "/reporting/:sensorId",
    component: _a759049a,
    name: "reporting-sensorId"
  }, {
    path: "/",
    component: _2d601e55,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
