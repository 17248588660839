<template>
  <div v-html="require(`@/assets/icons/${name}.svg?raw`)" />
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    name: {
      type: String,
      default: ''
    }
  }
}
</script>
