import axios from 'axios'

const axiosinstance = axios.create()
axiosinstance.interceptors.request.use(
  function (config) {
    // if (process.env.NODE_ENV === 'production') {
    //   // config.baseUrl = 'https://api.iot.niras.dk/'
    //   // [WEBAPI_ENDPOINT] bruges i build scripts og må ikke overskrives
    //   config.baseURL = '[WEBAPI_ENDPOINT]'
    // } else {
    // config.baseURL = 'https://api.iot.niras.dk/'
    // }
    // config.withCredentials = false
    // config.headers['Content-Type'] = 'application/json'
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default {
  get(url, request, config) {
    return axiosinstance.get(url, { params: request }, { headers: config })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  post(url, request) {
    return axiosinstance.post(url, request)
      .then(response => Promise.resolve(response))
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}
