import { NMapUtils } from 'ncomponents-map'
import axios from '@/plugins/axios.js'

export default async (ctx) => {
  if (ctx.$ua.browser() === 'Internet Explorer') {
    ctx.redirect('/supported_browsers')
  }
  NMapUtils.registerProjection('EPSG:4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs')
  await axios.post('https://api.iot.niras.dk/Userdata/GetLocationList', { projectId: 'e1ba78d4-146b-4ea6-9a0c-fc4ef14c4ffd' })
  .then(async (response) => {
    await ctx.store.dispatch('setManualSensors', response.data.items)
  })
  .catch((error) => {
    console.error('/Userdata/GetLocationList', error)
  })
  await axios.post('https://api.iot.niras.dk/Userdata/GetInputTypeList', {})
  .then(async (response) => {
    await ctx.store.dispatch('setInputTypeList', response.data.items)
  })
  .catch((error) => {
    console.error('/Userdata/GetInputTypeList', error)
  })
}
