<template>
  <div id="g-layout-footer">
    <div class="v-footer">
      <ul>
        <li>
          <a href="mailto:vandkortet@niras.dk">{{ $t('footer.contact') }}</a>
        </li>
        <li>
          <!-- <nuxt-link to="/privacy/privacy">{{ $t('footer.gdpr') }}</nuxt-link> -->
          <a href="https://www.niras.dk/privatlivspolitik-it-tjenester/" target="_blank">{{ $t('footer.gdpr') }}</a>
        </li>
      </ul>
      <cookie-law theme="dark-lime--rounded" :button-text="cookie_accept">
        <div slot="message">
          <!-- {{ cookie_message }} <nuxt-link to="/privacy/cookies" style="font-weight: bold">{{ cookie_linkText }}</nuxt-link> -->
          {{ cookie_message }} <a href="https://www.niras.dk/privatlivspolitik-it-tjenester/" target="_blank" style="font-weight: bold;">{{ cookie_linkText }}</a>
        </div>
      </cookie-law>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cookie_accept: this.$t('footer.cookie_accept'),
      cookie_message: this.$t('footer.cookie_message'),
      cookie_linkText: this.$t('footer.cookie_linkText')
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-footer {
    max-width: 90em;
    margin: 0 auto;

    ul li a {
      color: white;

      &:hover {
        color: #66b4ef;
      }
    }

    // .v-footer-icon {
    //   width: 25px;
    //   height: 25px;
    // }
  }

</style>
