<template>
  <div id="g-layout">
    <the-header></the-header>
    <notifications group="info" classes="vue-notification" position="top center" :speed="1000" />
    <nuxt />
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from '@/components/shared/headers/TheHeader.vue'
import TheFooter from '@/components/shared/footers/TheFooter.vue'
export default {
  name: 'DefaultLayout',
  components: {
    TheHeader,
    TheFooter
  },
  mounted() {
    const root = document.documentElement;

    document.addEventListener('mousemove', (event) => {
      if (!this.detectTouch()) {
        const x = event.clientX / innerWidth;
        const y = event.clientY / innerHeight;

        root.style.setProperty('--mouse-x', x);
        root.style.setProperty('--mouse-y', y);
      }
    });
  },
  methods: {
    detectTouch() {
      let isTouch = false;

      if ('ontouchstart' in window && 'orientation' in window && window.matchMedia('(pointer: coarse)').matches) {
        isTouch = true;
      }

      return isTouch
    }
  }
}
</script>
