import { render, staticRenderFns } from "./SimpleFooter.vue?vue&type=template&id=255d0163&scoped=true&"
var script = {}
import style0 from "./SimpleFooter.vue?vue&type=style&index=0&id=255d0163&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255d0163",
  null
  
)

export default component.exports