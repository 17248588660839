<template>
  <div id="g-layout-header" class="v-header">
    <div class="v-header-logo g-flexrow">
      <div class="v-logo">
        <base-icon :name="'logo'"></base-icon>
      </div>
      <nuxt-link to="/" :title="$t('header.title')">
        {{ $t('header.title') }}
      </nuxt-link>
      <div v-if="isMobile" class="v-header-mobile-menu" @click="toggleMobileMenu()">
        <base-icon :name="'hamburgermenu'"></base-icon>
      </div>
    </div>
    <div v-if="showMenu" class="v-header-menu">
      <nuxt-link to="/" :title="$t('header.menu.dashboard')" exact>{{ $t('header.menu.dashboard') }}</nuxt-link>
      <nuxt-link to="/reporting" :title="$t('header.menu.reporting')">{{ $t('header.menu.reporting') }}</nuxt-link>
      <nuxt-link to="/about" :title="$t('header.menu.about')">{{ $t('header.menu.about') }}</nuxt-link>
      <nuxt-link to="/overview" :title="$t('header.menu.overview')">{{ $t('header.menu.overview') }}</nuxt-link>
      <nuxt-link to="/images" :title="$t('header.menu.images')">{{ $t('header.menu.images') }}</nuxt-link>
      <nuxt-link to="/news/" :title="$t('header.menu.news')">{{ $t('header.menu.news') }}</nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      showMenu: window.innerWidth > 768
    }
  },
  created() {
    addEventListener('resize', () => {
      this.isMobile = innerWidth <= 768
      this.showMenu = innerWidth > 768
    })
  },
  methods: {
    toggleMobileMenu() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .v-logo {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .v-header-logo {
    // ALC remove when logo
    font-size: 1.5rem;

    a {
      color: white;
    }
  }
  .v-header-menu {
    display: flex;
    height: 100%;

    a {
      color: white;
      font-size: 1.1em;
      border-top: 5px solid transparent;
      border-bottom: 6px solid transparent;
      height: 100%;
      padding: 0 25px;
    }

    .nuxt-link-active {
      border-bottom: 6px solid white;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 768px) {
    .v-header {
      padding: 10px 20px;
      flex-direction: column;
    }
    .v-header-logo {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
    .v-header-mobile-menu {
      width: 25px;
      height: 25px;
      fill: white;
    }
    .v-header-menu {
      flex-direction: column;
      width: 100%;
    }
  }
  @media screen and (min-width: 769px) {
    .v-header {
      padding: 0 40px;
    }
    .v-header-logo {
      flex: 1 1 25%;
    }
    .v-header-menu {
      justify-content: space-evenly;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1407px) {
    .v-header {
      padding: 0 20px;
    }
  }
</style>
