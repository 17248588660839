<template>
  <img v-if="type === 'jpg' || type === 'jpeg' || type === 'png'" :alt="alt" :src="require(`@/assets/images/${name}.${type}`)">
  <div v-else v-html="require(`@/assets/images/${name}.svg?raw`)" />
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'png'
    },
    alt: {
      type: String,
      default: 'Billede'
    }
  }
}
</script>
