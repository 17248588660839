import Vue from 'vue'
import Notifications from 'vue-notification'
import CookieLaw from 'vue-cookie-law'
import { NMapStyleCircle } from 'ncomponents-map'
import BaseIcon from '@/components/base/icon/BaseIcon.vue'
import BaseImage from '@/components/base/image/BaseImage.vue'

Vue.use(Notifications)
Vue.component('base-icon', BaseIcon)
Vue.component('base-image', BaseImage)
Vue.component('cookie-law', CookieLaw)

Vue.prototype.$getSensorStyle = (sensorType) => {
  const colors = {
    automatic: '#ff0000',
    public: '#eec443',
    private: '#20201e',
    scala: '#06B18D'
  }
  return new NMapStyleCircle({
    fillColor: colors[sensorType] || '#bbbbbb',
    radius: 7,
    strokeColor: '#000000'
  })
}

Vue.prototype.$getSensorActiveStyle = (sensorType) => {
  const colors = {
    automatic: '#ff0000',
    public: '#eec443',
    private: '#20201e',
    scala: '#06B18D'
  }
  return new NMapStyleCircle({
    fillColor: colors[sensorType] || '#bbbbbb',
    radius: 12,
    strokeColor: '#000000'
  })
}

Vue.prototype.$getPopupContent = (alias) => {
  let content = ''
  content += '<div class="g-popup">' +
    '<div class="g-popup-header">' +
       alias +
    '</div>' +
  '<div>';

  return content
}
